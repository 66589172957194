import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { OrderInvoices } from 'ui/component/orders-list/order-details';
import {
    currentOrderVar,
    ISalesLayoutConfig,
    salesLayoutConfigVar,
} from '../../layouts/sales-layout/sales-layout-state';
import { IPageBreadcrumb } from '../../layouts/components/page-breadcrumbs';
import { SalesLayout } from '../../layouts/sales-layout';
import { OrderDetailsPageContent } from '../order-details-page-content';

const OrderInvoicesPage: React.FC = () => {
    const t = usePhraseTranslater();

    const pageTitle = t('Your Invoices');

    const config: ISalesLayoutConfig = useReactiveVar(salesLayoutConfigVar);
    const currentOrder: ICustomerOrder|undefined = useReactiveVar(currentOrderVar);

    const { salesConfig } = config;
    const { customerOrdersUrl } = salesConfig;

    const orderInvoicesPageBreadcrumbs: IPageBreadcrumb[] = [
        {
            label: t('Your Orders'),
            url: customerOrdersUrl,
        },
        {
            label: pageTitle,
        },
    ];

    const orderInvoicePageComponent: JSX.Element = (
        <OrderDetailsPageContent pageTitle={pageTitle}>
            <OrderInvoices config={config} order={currentOrder} />
        </OrderDetailsPageContent>
    );

    return (
        <SalesLayout
            pageKey="order-invoices"
            pageTitle={pageTitle}
            pageContent={orderInvoicePageComponent}
            pageBreadcrumbs={orderInvoicesPageBreadcrumbs}
            showLeftSidebar={false}
            loadCountries
            loadWidgets
            loadInvoices
            isGuestPage={false}
            splitOrderItems={false}
        />
    );
};

export { OrderInvoicesPage as default };
